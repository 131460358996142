import {
  Divider,
  Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../Store/actions/apiActions";
import { Box, useMediaQuery } from "@mui/system";
import ReactThrobber from "react-throbber";
import LaunchIcon from '@mui/icons-material/Launch';
import { useAuth } from "react-oidc-context";

const WeddingInfo = () => {
  const { currentInvitee, isLoading } = useSelector((state) => state.apiReducer);
  const dispatch = useDispatch();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const addNameToMap = (map, name) => {
    if (!name) return {};
    let splitName =  name.split(" ");
    const firstName = splitName.shift();
    const surname = splitName.join(" ");
    if (Object.keys(map).includes(surname)) {
      map[surname] = [ ...map[surname], firstName ]
      return map;
    }
    map[surname] = [firstName];
    return map;
  }

  const formatNames = (guestMap) => {
    const condensedNames = Object.entries(guestMap).map(([surname, firstNames]) => {
      if (firstNames.length > 1) {
        return `${firstNames.join(", ").replace(/, ([^,]*)$/, ' and $1')} ${surname}`;
      }
      return `${firstNames[0]} ${surname}`;
    });
  
    if (condensedNames.length > 1) {
      const lastTwo = condensedNames.splice(-2).join(" and ");
      return [...condensedNames, lastTwo].join(", ");
    }
    return condensedNames[0];
  }

  const getInviteeNames = () => {
    if (currentInvitee == null) { return null; }
    if (!currentInvitee?.additionalGuests || currentInvitee?.additionalGuests.length == 0) { return currentInvitee?.name; }
    let map = addNameToMap({}, currentInvitee?.name);
    currentInvitee?.additionalGuests.forEach((addedGuest) => {
      map = addNameToMap(map, addedGuest.name);
    });
    return formatNames(map);
  }

  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated 
        && auth?.user?.id_token
        // && auth?.user?.profile["custom:userId"]
    ) {
      dispatch(getCurrentUser(auth.user));
    }
  }, [dispatch, auth]);

  const getCustomDivider = () => {
    return (<Divider sx={{ borderBottomWidth: 2, borderBottomColor: "#FF7F50" }} style={{ margin: `30px ${lg ? 12 : 32}%`, width: `${lg ? 76 : 37}%` }} />);
  }

  return (
    <Grid container overflow="hidden">
      <Grid item xs={12}>
        <Box sx={{ paddingTop: isLoading ? "50px" : "10px" }}>
          {isLoading ?
            <ReactThrobber
              color="#FF7F50"
              size={lg   ? "100px" : "500px"}
              paddingTop={"200px"}
            />
            : <>
            <Typography variant="h2">
              Dearest {getInviteeNames()}
              </Typography>
              <Typography variant="h2">
                You are cordially invited to
              </Typography>
              {getCustomDivider()}
              <Typography variant="h2">
                The wedding of
              </Typography>
              <Typography
                variant="h1"
              >
                Gabriella Olivier
              </Typography>
              <Typography
                variant="h4"
              >
                and
              </Typography>
              <Typography
                variant="h1"
              >
                Marco Benvenuti
              </Typography>
              <Typography variant="h4">At</Typography>
              <Link
                href={"https://www.google.com/maps?saddr=My+Location&daddr=Waterberg+Game+Lodge"}
                target="_blank"
                rel="noopener noreferrer"
                color={"#FF7F50"}
              >
                <Typography
                  variant="h2"
                >
                  Waterberg Game Lodge
                  <LaunchIcon style={{ fontSize: lg ? "15px" : "30px", margin: lg ? "0px 0px 7px 3px" : "0px 0px 15px 5px"}}/>
                </Typography>
              </Link>
              <Typography variant="h3">
                Bela Bela, Limpopo
              </Typography>
              <Typography variant="h3">
                South Africa
              </Typography>
              {/* <Typography variant="h3">On the</Typography> */}
              {/* <Typography variant="h4">On the</Typography> */}
              <Typography variant="h2">
                22<sup>nd</sup> of March 2025 {/* 16:00 */}
              </Typography>
              <Typography variant="h2">
                16:00
              </Typography>
              <Typography variant="h2">
                Semi-Formal Attire
              </Typography>
              {getCustomDivider()}
              <Typography variant="h3">
                We ask that you RSVP online before 23:59 on the
                <strong> 15th of December 2024.</strong>
              </Typography>
              <Typography variant="h3">
                We look forward to seeing you there.
              </Typography>
              <br/>
              <Typography variant="h4">
                Please explore this website for further information and other
                interactions.
              </Typography>
            </>}
        </Box>
      </Grid>
    </Grid>
  );
};

export default WeddingInfo;

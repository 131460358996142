import React, { useState } from "react";
import { Grid, Box, Button, useTheme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import LoginComponent from "../../Components/Login/LoginComponent";

const LoginPage = () => {
  
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Grid container overflow="hidden">
      <Grid item xs={12} md={5}>
        <Box
          style={{
            position: "relative",
            backgroundImage: `url('/WeddingLogoSVG${xs ? "Mobile" : ""}.svg')`, // Replace with the actual path to your watermark image
            backgroundPosition: "-50% 95%", // Adjust as needed
            backgroundRepeat: "no-repeat", // Adjust as needed
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            
          }}
          sx={{
            height: "90vh",
          }}
        >
            <LoginComponent xs={xs} />
        </Box>
      </Grid>
      { !xs && <Grid item xs={0} md={7}>
        <Box
          style={{
            backgroundImage: `url('/loginPhoto1_4_800x1203.jpg')`, // Replace with the actual path to your watermark image
            backgroundPosition: "center 0%", // Adjust as needed
            backgroundRepeat: "no-repeat", // Adjust as needed
            backgroundColor: "rgba(255, 255, 255, 0)",
            marginTop: "5vh",
            overflow: "hidden",
            height: "90vh",
            width: "40vw",
          }}
        ></Box>
      </Grid>}{ xs &&
      <Grid item xs={12} md={0}>
        <Box
          style={{
            position: "relative",
            backgroundImage: `url('/loginPhoto1_4_800x1203.jpg')`, // Replace with the actual path to your watermark image
            backgroundPosition: "center 0%", // Adjust as needed
            backgroundRepeat: "no-repeat", // Adjust as needed
            backgroundColor: "rgba(255, 255, 255, 0)",
            overflow: "hidden",
            height: "90vh",
            width: "100vw",
          }}
        ></Box>
      </Grid>}
    </Grid>
  );
};

export default LoginPage;

import { Box, FormControl } from "@mui/material";
import CustomButton from "../../Components/Buttons/Button";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { useEffect, useState } from "react";

const LoginComponent = ({ xs }) => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  // automatically sign-in
  useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinSilent();
      if (!hasAuthParams() &&
        !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
        !hasTriedSignin) {
        auth.signinRedirect();
        setHasTriedSignin(true);
      }
    }
  }, [auth, hasTriedSignin]);

  return (
    <Box>
      {xs ? <h1 style={{ margin: 0 }}>Login</h1> : <h1>Login</h1>}
      <FormControl sx={{ m: 2, width: '25ch' }} variant="filled">
        <CustomButton
          onClick={() => auth.signinRedirect()}
          text="Click here to Login"
          style={{ height: "50px" }}
        />
      </FormControl>
    </Box>
  );
};

export default LoginComponent;

import axios from "axios";
import awsConfig from "../../config/awsConfig";
import { StoreConstants } from "../storeConstants";
import { getHeaders } from "../general";
import { setDefaultTimedError } from "./errorActions";
import { searchSpotify } from "./spotifyActions";

const initeeEndpoint = `${awsConfig.api_endpoint}/invitee`;

export const getInvitees = () => {
  return (dispatch, getState) => {
    dispatch({ type: StoreConstants.LOADING });
    axios
      .get(`${initeeEndpoint}`, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.GET_ALL_INVITEES_SUCCESS,
          payload: response.data.Items,
        });
      })
      .catch((error) => {
        dispatch({
          type: StoreConstants.GET_ALL_INVITEES_FAILED,
        });
      });
  };
};

export const getCurrentUser = (user) => {
  return (dispatch, getState) => {
    const token = user.id_token;
    const userId = user.profile["custom:userId"];
    const isAdmin = user.profile["cognito:groups"].some(group => group === "our-admins-user-group");
    dispatch(getInvitee(userId, true));
    dispatch({
      type: StoreConstants.REFRESH_CURRENT_SESSION_SUCCESS,
      payload: {
        token: token,
        userId: userId,
        isAdmin: isAdmin
      }
    });
  };
};

export const getLinkedInvitee = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: StoreConstants.LOADING });
    axios
      .get(`${initeeEndpoint}/${id}`, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.GET_LINKED_INVITEE_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: "CHANGE_SEARCH_URL",
          payload: response.data.rsvp?.songRequest?.url
        });
        if (response.data?.rsvp?.songRequest?.url.length > 0) {
          dispatch(searchSpotify());
        }
        else {
          dispatch({
            type: StoreConstants.CLEAR_SONG
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: StoreConstants.GET_LINKED_INVITEE_FAILED,
        });
        dispatch(setDefaultTimedError(error))
      });
  };
};

export const getInvitee = (id, currentUser = false) => {
  return (dispatch, getState) => {
    dispatch({ type: StoreConstants.LOADING });
    axios
      .get(`${initeeEndpoint}/${id}`, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: currentUser
            ? StoreConstants.GET_CURRENT_INVITEE_SUCCESS
            : StoreConstants.GET_INVITEE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: currentUser
            ? StoreConstants.GET_CURRENT_INVITEE_FAILED
            : StoreConstants.GET_INVITEE_FAILED,
        });
        dispatch(setDefaultTimedError(error))
      });
  };
};

export const adminSaveInvitee = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: StoreConstants.LOADING });
    const { invitees } = getState().apiReducer;
    let invitee = { ...invitees.find((i) => i.id === id) };
    const filteredAdditionalGuests = invitee.additionalGuests.filter(i => i.id !== null);
    invitee = {
      ...invitee,
      additionalGuests: filteredAdditionalGuests
    }
    dispatch(saveInvitee(invitee));
  };
};

export const saveInvitee = (invitee) => {
  return (dispatch, getState) => {
    dispatch({ type: StoreConstants.LOADING });
    axios
      .put(`${initeeEndpoint}/${invitee.id}`, invitee, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.SAVE_INVITEE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error))
        dispatch(getInvitee(invitee.id));
      });
  };
};

export const sendInvitation = (id) => {
  return (dispatch, getState) => {
    axios
      .post(`${initeeEndpoint}/${id}`, null, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.SEND_INVITATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error))
        dispatch(getInvitee(id));
      });
  };
};

export const resetUser = (id) => {
  return (dispatch, getState) => {
    axios
      .post(`${initeeEndpoint}/${id}/reset-user`, null, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.SEND_INVITATION_SUCCESS,
          payload: response.data,
        });
        dispatch(getInvitee(id));
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error))
        dispatch(getInvitee(id));
      });
  };
};

export const deleteInvitee = (id) => {
  return (dispatch, getState) => {
    axios
      .delete(`${initeeEndpoint}/${id}`, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.DELETE_INVITEE_SUCCESS,
          payload: id,
        });
      })
      .catch((error) => {
        dispatch(getInvitee(id));
        dispatch(setDefaultTimedError(error))
      });
  };
};

export const changeRsvp = (rsvp, currentInvitee) => {
  return (dispatch) => {
    dispatch({
      type: StoreConstants.GET_LINKED_INVITEE_SUCCESS,
      payload: {
        ...currentInvitee,
        rsvp: rsvp,
      },
    });
    dispatch(
      saveInvitee({
        ...currentInvitee,
        rsvp: rsvp,
      })
    );
  };
};

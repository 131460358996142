import { StoreConstants } from "../storeConstants";
import { setDefaultTimedError } from "./errorActions";
import awsConfig from "../../config/awsConfig";
import axios from "axios";

export const refreshCurrentSession = (id_token, id, admin) => {
  return (dispatch, ) => {
    if (id_token && id) {
      dispatch({
        type: StoreConstants.REFRESH_CURRENT_SESSION_SUCCESS,
        payload: {
          userId: id,
          isAdmin: admin,
          token: id_token,
        },
      });
    }
  };
};

const featuresEndpoint = `${awsConfig.api_endpoint}/features`;
export const getFeatureToggles = () => {
  return (dispatch, getState) => {
    dispatch({ type: StoreConstants.LOADING });
    axios
      .get(`${featuresEndpoint}`, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.GET_FEATURE_TOGGLES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: StoreConstants.GET_FEATURE_TOGGLES_FAILED
        });
        dispatch(setDefaultTimedError(error))
      });
  }
}
export const setFeatureToggles = (toggles) => {
  return (dispatch, getState) => {
    dispatch({ type: StoreConstants.LOADING });
    axios
      .put(`${featuresEndpoint}`, toggles, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.PUT_FEATURE_TOGGLES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error));
      });
  };
}

const getHeaders = (getState) => {
  const { session } = getState().userReducer;
  return {
    Authorization: `Bearer ${session?.token}`,
    "Content-Type": "application/json",
  };
};

import { Close } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Dialog, DialogTitle, Divider, Grid, IconButton, Paper } from "@mui/material";


const SummaryPopup = ({ allInvitees, showPopup, hidePopup }) => {

    const getAccordion = (title, predicate) => {
        const filteredInvitees = allInvitees.filter(predicate);
        return (
            <Accordion style={{width: "100%"}}>
                <AccordionSummary><h3>{title}: {filteredInvitees.length}</h3></AccordionSummary>
                <AccordionDetails>
                    <Divider style={{width: "100%"}}/>
                    <Grid container>
                        {filteredInvitees.map(i => <Grid item xs={6} md={4}><p>{i.name}</p></Grid>)}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
    <Dialog open={showPopup} onClose={hidePopup} style={{width: "100vw%", maxWidth: "100vw"}}>
        <DialogTitle>
            <Grid container>

                <Grid item xs={11}>

                    <h2>RSVP Summary:</h2>
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={() => hidePopup()}>
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>
        </DialogTitle>
        <Box style={{width: "100%"}}>
            <Paper style={{width: "100%"}}>
                {getAccordion("Total Invites", i => true)}
                {getAccordion("Yes's", i => i.rsvp.wedding === "yes")}
                {getAccordion("No's", i => i.rsvp.wedding === "no")}
                {getAccordion("No Responses", i => i.rsvp.wedding === "none")}
                {getAccordion("Staying at the venue", i => i.rsvp.wedding === "yes" && i.rsvp.accomodation)}
                {getAccordion("Not staying at the venue", i => i.rsvp.wedding === "yes" && !i.rsvp.accomodation)}
                {getAccordion("Breakfast (not staying)", i => i.rsvp.wedding === "yes" && !i.rsvp.accomodation && i.rsvp.breakfast)}
                {getAccordion("Bachelors", i => i.rsvp.bachelors)}
                {getAccordion("Bachelorette", i => i.rsvp.bachelorette)}
                {getAccordion("Kitchen Tea", i => i.rsvp.wedding === "yes" && i.rsvp.gardenTea)}
            </Paper>
        </Box>
    </Dialog >);
}

export default SummaryPopup;
import { Button, Divider, Grid, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Invitee from "../../Components/AdminComponents/Invitee";
import CustomButton from "../../Components/Buttons/Button";
import { useEffect, useState } from "react";
import {
  adminSaveInvitee,
  deleteInvitee,
  getInvitees,
  resetUser,
  sendInvitation,
} from "../../Store/actions/apiActions";
import { useDispatch, useSelector } from "react-redux";
import { StoreConstants } from "../../Store/storeConstants";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import CasinoIcon from "@mui/icons-material/Casino";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import BungalowIcon from '@mui/icons-material/Bungalow';
import AddHomeIcon from '@mui/icons-material/AddHome';
import SummaryPopup from "./SummaryPopup";
import { Summarize } from "@mui/icons-material";

const Invitees = () => {
  const { invitees } = useSelector((state) => state.apiReducer);
  const dispatch = useDispatch();

  const [showSummaryDialog, setShowSummaryDialog] = useState(false);

  const showSummary = () => setShowSummaryDialog(true);
  const hideSummary = () => setShowSummaryDialog(false);

  const enableInviteeEdit = (id) => {
    dispatch({
      type: StoreConstants.UPDATE_USER,
      payload: {
        id: id,
        key: "isEditMode",
        value: true,
      },
    });
  };

  const disableInviteeEdit = (id) => {
    dispatch({
      type: StoreConstants.UPDATE_USER,
      payload: {
        id: id,
        key: "isEditMode",
        value: false,
      },
    });
  };

  const handleEditOrConfirm = (invitee) => {
    if (invitee.isEditMode) {
      disableInviteeEdit(invitee.id);
      dispatch(adminSaveInvitee(invitee.id));
    } else {
      enableInviteeEdit(invitee.id);
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteInvitee(id));
  };

  const handleInviteeChange = (id, key, value) => {
    dispatch({
      type: StoreConstants.UPDATE_USER,
      payload: {
        id: id,
        key: key,
        value: value,
      },
    });
  };

  const handleRsvpChange = (id, value) => {
    dispatch({
      type: StoreConstants.UPDATE_USER,
      payload: {
        id: id,
        key: "rsvp",
        value: value,
      },
    });
  };

  const addNewInvitee = () => {
    dispatch({
      type: StoreConstants.ADD_EMPTY_INVITEE,
      payload: {
        id: `${getNextId()}`,
        isEditMode: true,
        name: "",
        email: "",
        phoneNumber: "",
        inviteSent: false,
        bachelors: false,
        bachelorette: false,
        gardenTea: false,
        fridayNight: false,
        fullAccomodation: false,
        additionalGuests: [],
        rsvp: {
          wedding: "none",
          bachelors: false,
          bachelorette: false,
          gardenTea: false,
          accomodation: false,
          dietaryRestrictions: "",
          additionalGuests: [],
          songRequest: {
            title: "",
            artist: "",
            url: "",
          },
        },
      },
    });
  };

  const handleSendInvitation = (id) => {
    dispatch(sendInvitation(id));
  };

  const handleUserReset = (id) => {
    dispatch(resetUser(id));
  };

  const handleSendAllButton = () => {
    invitees
      .filter((i) => !i.inviteSent)
      .forEach((invitee) => {
        handleSendInvitation(invitee.id);
      });
  };

  const showSendAllButton = () => {
    return invitees.some((i) => !i.inviteSent);
  };

  const handleEditAllButton = () => {
    invitees.forEach((invitee) => {
      enableInviteeEdit(invitee.id);
    });
  };

  const showEditAllButton = () => {
    return invitees.some((i) => !i.isEditMode);
  };

  const handleSaveAllButton = () => {
    invitees
      .filter((i) => i.isEditMode)
      .forEach((invitee) => {
        handleEditOrConfirm(invitee.id);
      });
  };

  const showSaveAllButton = () => {
    return invitees.some((i) => i.isEditMode);
  };

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const getInviteeCount = () => {
    return invitees.map(i => 1).reduce((p, c) => p + c, 0);
  }


  const getYesCount = () => {
    return invitees.map(i => i.rsvp.wedding === "yes").reduce((p, c) => p + c, 0);
  }

  const getNoResponseCount = () => {
    return invitees.map(i => i.rsvp.wedding === "none").reduce((p, c) => p + c, 0);
  }

  const getAllInvitees = (invitee) => {
    const alreadyLinkedIds = invitee.additionalGuests.map(i => i.id);
    return invitees.filter(i => invitee?.id !== i?.id && !alreadyLinkedIds.includes(i.id)).map(i => ({ id: i.id, label: i.name }));
  }

  useEffect(() => {
    // Function to fetch data from the API
    async function fetchData() {
      dispatch(getInvitees());
    }
    // Call the fetchData function when the component mounts
    fetchData();
  }, [dispatch]);

  const getNextId = () => {
    if (invitees.length === 0) return 0;
    return Math.max(...invitees.map((invitee) => invitee.id)) + 1;
  };

  return (
    <Grid
      justifyContent="center"
      style={{ width: "95vw", height: "75vh", padding: "15px" }}
    >
      <SummaryPopup allInvitees={invitees} hidePopup={hideSummary} showPopup={showSummaryDialog} />
      <Grid item xs={12}>
        {xs && <Grid container><Grid item xs={10} />
          <Grid item xs={2}>
            <Button onClick={() => showSummary()}>
              <Summarize />
            </Button>
          </Grid></Grid>}
        {!xs && <Grid container key={"header"}>
          <Grid item style={{ width: "50px" }} />
          <Grid item xs={2}>
            Name
          </Grid>
          <Grid item xs={2}>
            Email
          </Grid>
          <Grid item style={{ width: "265px" }} />
          <Grid item xs={3} style={{ textAlign: "left" }}>
            <Grid container>
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Tooltip title="Fully Paid Accomodation">
                  <BungalowIcon />
                </Tooltip>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Tooltip title="Friday Night">
                  <AddHomeIcon />
                </Tooltip>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Tooltip title="Bachelorette Party">
                  <CasinoIcon />
                </Tooltip>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Tooltip title="Kitchen Tea">
                  <EmojiFoodBeverageIcon />
                </Tooltip>
              </Grid>
              <Grid item xs={2} style={{ textAlign: "left" }}>
                <Tooltip title="Bachelor Party">
                  <GolfCourseIcon />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: "35px" }} />
          <Grid item xs={2} style={{ textAlign: "left" }}>
            <Grid container>
              <Grid item xs={10} />
              <Grid item xs={2}>
                <Tooltip title={`Summary: \nTotal: ${getInviteeCount()}\nYes:${getYesCount()}\nNo Response:${getNoResponseCount()}\n`}>
                  <Button onClick={() => showSummary()}>
                    <Summarize />
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container style={{ maxHeight: "60vh", overflowY: "auto" }}>
            {invitees
              .sort((a, b) => a.id - b.id)
              .map((invitee) => (
                <Grid item xs={12} key={invitee.id}>
                  <Invitee
                    invitee={invitee}
                    allInvitees={getAllInvitees(invitee)}
                    handleEditOrConfirm={handleEditOrConfirm}
                    onDelete={handleDelete}
                    handleInviteeChange={handleInviteeChange}
                    handleRsvpChange={handleRsvpChange}
                    handleSendInvitation={handleSendInvitation}
                    handleUserReset={handleUserReset}
                  />
                  <Divider />
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={10} style={{ textAlign: "right" }}>
        <CustomButton
          onClick={() => {
            addNewInvitee();
          }}
          text="Add"
        />
        {showEditAllButton && (
          <CustomButton
            onClick={() => {
              handleEditAllButton();
            }}
            text="Edit All"
          />
        )}
        {showSaveAllButton && (
          <CustomButton
            onClick={() => {
              handleSaveAllButton();
            }}
            text="Save All"
          />
        )}
        {showSendAllButton() && (
          <CustomButton
            onClick={() => {
              handleSendAllButton();
            }}
            text="Send Unsent Invites"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Invitees;

import {
  Grid,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../Store/actions/apiActions";
import { Box } from "@mui/system";
import CustomDivider from "../../Components/Common/CustomDivider";
import { useAuth } from "react-oidc-context";

const RehearsalDinner = () => {
  const { currentInvitee } = useSelector((state) => state.apiReducer);
  const dispatch = useDispatch();
  
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated 
        && auth?.user?.id_token
        // && auth?.user?.profile["custom:userId"]
    ) {
      dispatch(getCurrentUser(auth.user));
    }
  }, [dispatch, auth]);

  const MyCustomDivider = () => {
    return <CustomDivider style={{ margin: `15px 40%`, width: `20%` }} />
  }

  return (
    <Grid container overflow="hidden">
      <Grid item xs={0} md={3} />
      <Grid item xs={12} md={6}>
        <Box>
          <br />
          <Typography variant="h3" sx={{ mt: 1 }}>
            Dear {currentInvitee?.name}
          </Typography>
          <MyCustomDivider />
          <Typography variant="h4">
          We are delighted to invite you to the rehearsal dinner. 🍽️
          </Typography>
          <Typography variant="h4">
          Please mark your calendar for Friday, the 21st of March 2025 at Waterberg Estate.
          </Typography>
          <Typography variant="h4">
            More details will follow, including the time.
          </Typography>
          <Typography variant="h4">
          Your presence would mean the world to us.
          </Typography>
          <MyCustomDivider />
          <Typography variant="h4">
          With warm regards,
          </Typography>
          <Typography variant="h4">
          Your Bride & Groom
          </Typography>
          <Typography variant="h3">
            Gabriella & Marco
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={0} md={3} />
    </Grid>
  );
};

export default RehearsalDinner;

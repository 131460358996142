import './App.css';
import MainComponent from './Pages/MainComponent';
import { ThemeProvider, createTheme } from '@mui/material/styles';

function App() {

  const theme = createTheme();
  const fontSize = 14; // in pixels
  const htmlFontSize = 16; // default font size used by browsers
  const coef = fontSize / 14;

  theme.palette.mode = "dark";
  theme.palette.primary.main = "#1A0062";
  theme.palette.secondary.main = "#FF7F50";
  theme.palette.primary.dark = "#1A0062";
  theme.palette.secondary.dark = "#FF7F50";
  theme.palette.primary.light = "#1A0062";
  theme.palette.secondary.light = "#FF7F50";

  theme.typography = {
    pxToRem: (size) => `${(size / htmlFontSize) * coef}rem`,
    h1: {
      fontSize: '2.35rem',
      color: "#1A0062",
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontSize: '3.5rem',
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '5.5rem',
      }
    }, h2: {
      fontSize: '1.85rem',
      color: "#1A0062",
      fontWeight: 400,
      [theme.breakpoints.up('md')]: {
        fontSize: '3.2rem',
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '4.2rem',
      }
    }, h3: {
      fontSize: '1.5rem',
      color: "#1A0062",
      fontWeight: 300,
      [theme.breakpoints.up('md')]: {
        fontSize: '3rem',
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '3.2rem',
      }
    }, h4: {
      fontSize: '1.3rem',
      color: "#1A0062",
      fontWeight: 200,
      [theme.breakpoints.up('md')]: {
        fontSize: '2.5rem',
      },
      [theme.breakpoints.only('xl')]: {
        fontSize: '2.5rem',
      }
    }, h5: {
      fontSize: '1.2rem',
      color: "#1A0062",
      fontWeight: 100,
      [theme.breakpoints.only('xl')]: {
        fontSize: '2.4rem',
      }
    },
  };

  theme.components.MuiSwitch = {
    styleOverrides: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: "#FF7F50",
      },
      colorPrimary: {
        "&.Mui-checked": {
          // Controls checked color for the thumb
          color: "#FF7F50",
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.4,
        backgroundColor: "#1A0062",
        ".Mui-checked.Mui-checked + &": {
          // Controls checked color for the track
          opacity: 0.8,
          backgroundColor: "#1A0062"
        }
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Satisfy&display=swap" rel="stylesheet" />
        <MainComponent />
      </div>
    </ThemeProvider>
  );
}

export default App;


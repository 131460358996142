import {
  Grid,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../Store/actions/apiActions";
import { Box } from "@mui/system";
import CustomDivider from "../../Components/Common/CustomDivider";
import { useAuth } from "react-oidc-context";

const Gifts = () => {
  const { currentInvitee } = useSelector((state) => state.apiReducer);
  const dispatch = useDispatch();

  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated 
        && auth?.user?.id_token
        // && auth?.user?.profile["custom:userId"]
    ) {
      dispatch(getCurrentUser(auth.user));
    }
  }, [dispatch, auth]);
  
  const MyCustomDivider = () => {
    return <CustomDivider style={{ margin: `15px 40%`, width: `20%` }} />
  }

  return (
    <Grid container>
      <Grid item xs={1} md={3} />
      <Grid item xs={10} md={6}>
        <Box
          style={{
            position: "relative",
            backgroundImage: `url('/WeddingLogoWatermark.svg')`, // Replace with the actual path to your watermark image
            backgroundPosition: "center", // Adjust as needed
            backgroundRepeat: "no-repeat", // Adjust as needed
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          }}
          sx={{
            width: "100%",
          }}
        >
          <br/>
          <Typography variant="h3">
            Dearest {currentInvitee?.name},
          </Typography>
          <MyCustomDivider/>
          <Typography variant="h4">
            We are greatful for any gifts we receive.
          </Typography>
          <Typography variant="h4">
            We are in the process of finalizing our gift registry and
          </Typography>
          <Typography variant="h4">
            will provide you with more information soon,
          </Typography>
          <Typography variant="h4">
            via this page.
          </Typography>
          <Typography variant="h4">
            Thank you for your patience.
          </Typography>
          <MyCustomDivider/>
          <Typography variant="h4">
            The Bridal Party.
          </Typography>
          <Typography variant="h3">
            Gabriella & Marco
          </Typography>
          {/* <Typography variant="h4">
            Please make use of the following bank details:
          </Typography>
        <Box variant="h4">
          <Typography variant="h4">
            Name: Elizabeth Lemmer
          </Typography>
          <Typography variant="h4">
            Account Number: 9185242475
          </Typography>
          <Typography variant="h4">
            Bank: Absa Bank
          </Typography>
          <Typography variant="h4">
            Branch Number: 632005
          </Typography>
          </Box> */}
          <br/>
        </Box>
      </Grid>
      <Grid item xs={1} md={3} />
    </Grid>
  );
};

export default Gifts;

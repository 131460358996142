import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
  OutlinedInput,
  Select,
  Typography,
  MenuItem,
  Tabs,
  Tab,
  FormControl,
  InputLabel
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeRsvp,
  getLinkedInvitee,
} from "../../Store/actions/apiActions";
import SpotifySong from "../Playlist/SpotifySong";
import { isAfterRSVPDate } from "../../Utils/InviteeUtil";
import CustomButton from "../Buttons/Button";
import CustomDivider from "../Common/CustomDivider";

const RSVP = () => {
  const [isAfterRsvpDate, setisAfterRsvpDate] = useState(false);
  const { currentInvitee, rsvpInvitee } = useSelector((state) => state.apiReducer);
  const [dietaryRestrictions, setDietaryRestrictions] = useState("");
  const [message, setMessage] = useState("");
  const [tab, setTab] = useState("");

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  const handleTabChange = (v) => {
    setTab(v);
    dispatch(getLinkedInvitee(v));
  };
  
  const MyCustomDivider = () => {
    return <CustomDivider style={{ margin: `15px 40%`, width: `20%` }} />
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setisAfterRsvpDate(isAfterRSVPDate());
    }, 1000);

    if (rsvpInvitee) {
      setDietaryRestrictions(rsvpInvitee.rsvp?.dietaryRestrictions);
      setMessage(rsvpInvitee.rsvp?.message);
      setTab(rsvpInvitee.id);
    }
    else if (currentInvitee?.id) {
      dispatch(getLinkedInvitee(currentInvitee.id));
    }

    return () => clearInterval(interval);
  }, [currentInvitee, rsvpInvitee, dispatch]);

  let invitees = [
    {
      id: currentInvitee?.id,
      name: currentInvitee?.name,
    },
    ...(currentInvitee?.additionalGuests || []),
  ];

  const handleChangeWeddingResponse = (event) => {
    if (rsvpInvitee.rsvp.wedding !== event.target.value) {
      handleRSVPChange({
        ...rsvpInvitee.rsvp,
        wedding: event.target.value,
      });
    }
  };

  const handleChangeAccomodationResponse = (event) => {
    handleRSVPChange({
      ...rsvpInvitee.rsvp,
      accomodation: !rsvpInvitee.rsvp.accomodation,
    });
  };

  const handleChangeBreakfastResponse = (event) => {
    handleRSVPChange({
      ...rsvpInvitee.rsvp,
      breakfast: !rsvpInvitee.rsvp.breakfast,
    });
  };

  const handleMessageSave = () => {
    handleRSVPChange({
      ...rsvpInvitee.rsvp,
      message: message,
    });
  };

  const handleRSVPChange = (rsvp) => {
    dispatch(changeRsvp(rsvp, rsvpInvitee));
  };

  const submitDietaryRestrictions = () => {
    handleRSVPChange({
      ...rsvpInvitee.rsvp,
      dietaryRestrictions: dietaryRestrictions,
    });
  };

  return (
    <Grid container minHeight="60vh">
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={8}>
        <h2>Will you be joining us?</h2>
        <Typography variant="h4">
          {(currentInvitee && rsvpInvitee && invitees && invitees.length > 0) && (md ?
            <FormControl sx={{ width: "90%", margin: "0" }}>
              <InputLabel>Responding For:</InputLabel>
              <Select sx={{ width: "100%" }} value={tab}>
                {invitees.map(guest => <MenuItem value={guest.id}
                  onClick={() => { handleTabChange(guest.id); }}
                  centered
                  textColor="secondary"
                  style={{ color: "#1A0062", textAlign: "center" }}>
                  <Typography variant="h4">{guest.name}</Typography>
                </MenuItem>)}
              </Select>
            </FormControl>
            :
            <Tabs
              value={tab}
              onChange={(t, v) => { handleTabChange(v); }}
              centered
              indicatorColor="secondary"
              textColor="secondary"
            >
              {invitees?.map(guest =>
                <Tab sx={{ textTransform: "none", color: "#1A0062", fontFamily: "Satisfy; cursive", fontSize: "36px", fontWeight: "500" }} label={guest.name} key={guest.id} value={guest.id} />)}
            </Tabs>)}
        </Typography>
        <ToggleButtonGroup
          value={rsvpInvitee?.rsvp?.wedding ?? "none"}
          exclusive
          onChange={handleChangeWeddingResponse}
          aria-label="Platform"
          disabled={isAfterRsvpDate}
          fullWidth={"100%"}
          style={{ backgroundColor: "#FF7F5060" }}
        >
          <ToggleButton value="yes" style={{ color: "#1A0062" }}>
            <strong>Yes</strong>
          </ToggleButton>
          {rsvpInvitee?.rsvp?.wedding === "none" &&
            <ToggleButton value="none" style={{ color: "#1A0062" }} >
              <strong>No Response</strong>
            </ToggleButton>}
          <ToggleButton value="no" style={{ color: "#1A0062" }}>
            <strong>No</strong>
          </ToggleButton>
        </ToggleButtonGroup>
        <MyCustomDivider/>
      </Grid>
      <Grid item xs={0} md={2} />
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={8}>
        {(!rsvpInvitee || rsvpInvitee?.rsvp?.wedding === "none") && (
          <>
            <h3 style={{ color: "#1A0062" }}>
              We look forward to hearing from you before
              <br />
              December 16th, 2024
            </h3>
            <p style={{ margin: `0 ${xs ? 5 : 25}% 0 ${xs ? 5 : 25}%`, fontSize: xs ? "24px" : "32px", width: xs ? "90%" : "50%" }}>
              Please note: if you have not responded by 15&nbsp;December&nbsp;23:59,
              your response will automatically be set to 'No' and locked.
            </p>
          </>
        )}
        {rsvpInvitee?.rsvp?.wedding === "no" && (<>
          <h3>Sorry we can't share this lovely day with you!</h3>
          <p>Your presence will be missed. Please feel free to leave us a heartfelt message.</p>
          <OutlinedInput multiline fullWidth rows={10} style={{ border: "1px solid black", fontSize: "20px", backgroundColor: "#00000000", font: "inherit", color: "#1A0062" }}
            value={message}
            onChange={event => setMessage(event.target.value)}
          />
          <Grid container>
            <Grid item xs={11} />
            <Grid item xs={1}>
              <CustomButton
                onClick={
                  handleMessageSave
                }
                text="Save"
              />
            </Grid>
          </Grid>
        </>
        )}
      </Grid>
      <Grid item xs={0} md={2} />
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={8}>
        {rsvpInvitee?.rsvp?.wedding === "yes" && (<>
          <Accordion
            expanded={true}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              color: "#1A0062"
            }}
          >
            <AccordionSummary sx={{ pointerEvents: "none" }}>
              <Grid container>
                <Grid item xs={12}>
                  <p style={{
                    // fontSize: xs ? "12px" : "20px",
                  }}>Will you also be staying with us at the venue?</p>
                </Grid>
                <Grid item xs={12}>
                  {
                    !rsvpInvitee?.fullAccomodation &&
                    <ul style={{ fontSize: xs ? "16px" : "24px", textAlign: 'justify', margin: 0, paddingInlineStart: xs ? "10px" : "40px" }}>
                      <li><p style={{ margin: "0" }}>“Yes” does not guarantee accommodation as Waterberg can host up to 85 guests.</p></li>
                      <li><p style={{ margin: "0" }}>Rooms will be thoughtfully assigned based on the order of responses and confirmed on 22 December.</p></li>
                      <li><p style={{ margin: "0" }}>Please note: The cost to stay at the venue is R2425 per couple sharing or R1485 for a single, including breakfast.</p></li>
                      <li><p style={{ margin: "0" }}>Payment to stay at the venue is due by 16 January 2025 and can be made to us, as requested by the venue.</p></li>
                    </ul>}
                  {
                    rsvpInvitee?.fullAccomodation && <>
                      <p>
                        We are truly delighted to have you join us for our special day. It would mean so much to us to host you at the beautiful Waterberg.
                        We warmly invite you for Friday to join us for the rehearsal and also for the ceremony on Saturday.
                      </p><p>
                          Rest assured, your accommodation will be thoughtfully arranged by us. We can't wait to see you and share this memorable moment together.
                      </p>
                    </>}
                  {rsvpInvitee?.fridayNight && !rsvpInvitee?.fullAccomodation ? <p style={{ fontSize: xs ? "20px" : "28px" }}>We would also like to extend the invitation for you to join us on friday night at the lodge as well,
                    for the rehearsal dinner together.
                  </p> : <></>}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={10} md={11}>
                  <p style={{ fontSize: xs ? "24px" : "32px" }}>
                    {rsvpInvitee?.rsvp?.accomodation
                      ? md
                        ? `Yes, I will be staying on the venue premises.`
                        : "Yes, staying at the venue."
                      : md
                        ? `No, I will not be staying on the venue premises.`
                        : "No, not staying at the venue."}
                  </p>
                </Grid>
                <Grid item xs={2} md={1}>
                  <Switch
                    checked={rsvpInvitee?.rsvp?.accomodation}
                    color="warning"
                    onChange={handleChangeAccomodationResponse}
                    disabled={isAfterRsvpDate}
                    sx={{ margin: xs ? "20px 0px" : "39px 0px" }}
                  />
                </Grid>
                {!rsvpInvitee?.rsvp?.accomodation ? (<>
                  <Grid xs={12}>
                    <p style={{ marginBottom: 0 }}>Would you still like to join us for breakfast on Sunday?</p>
                    <p style={{ fontSize: xs ? "16px" : "24px", margin: 0 }}>(R195 per person at your own expense)</p>
                    <p></p>
                  </Grid>
                  <Grid item xs={10} md={11}>
                    <p>
                      {rsvpInvitee?.rsvp?.breakfast
                        ? md
                          ? `Yes, I will be joining for breakfast.`
                          : "Yes, joining for breakfast."
                        : md
                          ? `No, I will not be joining for breakfast.`
                          : "No, not joining for breakfast."}
                    </p>
                  </Grid>
                  <Grid item xs={2} md={1}>
                    <Switch
                      checked={rsvpInvitee?.rsvp?.breakfast}
                      color="warning"
                      onChange={handleChangeBreakfastResponse}
                      disabled={isAfterRsvpDate}
                      sx={{ margin: xs ? "20px 0px" : "39px 0px" }}
                    />
                  </Grid>
                </>) :
                (<>
                  <Grid item xs={10} md={11}>
                  <p style={{ fontSize: xs ? "16px" : "24px", margin: 0 }}>This does also include breakfast with us on Sunday.</p>
                  <p></p>
                </Grid>
                <Grid item xs={2} md={1}/>
              </>)}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={true}
            hidden={rsvpInvitee?.rsvp?.wedding !== "yes"}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              color: "#1A0062"
            }}
          >
            <AccordionSummary sx={{ pointerEvents: "none" }}>
              <h4>Dietry Requirements & Allergens:</h4>
            </AccordionSummary>
            <AccordionDetails>
              <OutlinedInput style={{ border: "1px solid #1A0062", color: "#1A0062" }} value={dietaryRestrictions} fullWidth onChange={event => setDietaryRestrictions(event.target.value)} onBlur={submitDietaryRestrictions}></OutlinedInput>
            </AccordionDetails>
          </Accordion>
        </>
        )}
      </Grid>
      <Grid item xs={0} md={2} />
      <Grid item xs={0} md={2} />
      <Grid item xs={12} md={8}>
        {rsvpInvitee?.rsvp?.wedding === "yes" && (
          <Accordion
            expanded={true}
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              color: "#1A0062"
            }}
          >
            <AccordionSummary sx={{ pointerEvents: "none" }}>
              <h3>Your Song Request:</h3>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "8px 8px 8px" }}>
              <Grid item xs={12}>
                <SpotifySong disabled={isAfterRsvpDate} />
                <ol style={{ paddingInlineStart: "35%", textAlign: "justify" }}>
                  <li style={{ fontSize: xs ? "12px" : "20px", marginBottom: 0 }}>Search any spotify song and copy the link.</li>
                  <li style={{ fontSize: xs ? "12px" : "20px", marginTop: 0, marginBottom: 0 }}>Paste it here and search to ensure you find the song.</li>
                  <li style={{ fontSize: xs ? "12px" : "20px", marginTop: 0, marginBottom: 0 }}>If the search was a success, the song should appear.</li>
                  <li style={{ fontSize: xs ? "12px" : "20px", marginTop: 0, marginBottom: 0 }}>Submit your choice by clicking the Save Button </li>
                  <p style={{ fontSize: xs ? "12px" : "20px", marginTop: 0, }}>*You can change your song by repeating the steps</p>
                </ol>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
      <Grid item xs={0} md={2} />
    </Grid>
  );
};
export default RSVP;

import { useDispatch, useSelector } from "react-redux";
import Home from "./User/Home";
import LoginPage from "./User/login";
import { Alert, Box, IconButton, Snackbar } from "@mui/material";
import SpotifyWidget from "../Components/Playlist/SpotifyWidget";
import { clearError } from "../Store/actions/errorActions";
import { Close } from "@mui/icons-material";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { useEffect, useState } from "react";
import { refreshCurrentSession } from "../Store/actions/userActions";

const MainComponent = () => {
  const { isError, errorMessage } = useSelector((state) => state.errorReducer);
  const auth = useAuth();
  const dispatch = useDispatch();


  useEffect(() => {
    if (auth.isAuthenticated
      && auth?.user?.id_token
      && auth?.user?.profile["custom:userId"]
    ) {
      dispatch(refreshCurrentSession(auth?.user?.id_token, auth?.user?.profile["custom:userId"], true));
    }
    if (!hasAuthParams()
      && !auth.activeNavigator
      && !auth.isLoading
    ) {
      auth.signinRedirect();
    }
    setIsAuthenticated(auth.isAuthenticated);
  }, [dispatch, auth]);


  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleClose = () => { dispatch(clearError()); };

  return (
    <>
      <Box overflow="hidden">
        <Snackbar
          open={isError}
          autoHideDuration={5000}
          onClose={handleClose}
          color="red"
          action={<IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}>
            <Close />
          </IconButton>}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {errorMessage?.message}</Alert>
        </Snackbar>
        {isAuthenticated ? <Home auth={auth} /> : <LoginPage />}
      </Box>
      <SpotifyWidget />
      <p>V3.0 © 2023 Marco Benvenuti - All rights reserved.</p>
    </>
  );
};

export default MainComponent;
